import EventBust from './eventBust';

const EVENT_NAME = 'topbarSearch';
export default {
  EVENT_NAME,
  emit(...params) {
    EventBust.$emit(EVENT_NAME, ...params);
  },
  on(...params) {
    EventBust.$on(EVENT_NAME, ...params);
  },
  off() {
    EventBust.$off(EVENT_NAME);
  },
};
