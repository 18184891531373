<template>
  <AppDrawer right class="notification-drawer-drawer" v-model="innerVal">
    <div class="notification-drawer d-flex flex-column">
      <h4 class="notification-drawer-header d-flex p-2 flex-shrink-0">
        <span>NOTIFICATION</span>
        <AppIcon
          hoverable
          name="close"
          class="ml-auto"
          @click="innerVal = false"
        ></AppIcon>
      </h4>

      <div class="notification-drawer-content flex-shrink-1">
        <div
          v-for="(notification, index) in notifications"
          :key="`notification-list-${index}`"
        >
          <p
            class="m-0 p-2 bg-gray-light-4 text-uppercase font-style-secondary"
          >
            {{ timePassedCal(notification.date).datePassed }}
          </p>

          <div
            v-for="(notif_item, notifIndex) in notification.notifications"
            :key="`notification-item-${notif_item.id}-${notifIndex}`"
            class="notification-drawer-content-item p-2 row"
          >
            <!-- profile image -->
            <div v-if="false" class="col-3 d-flex justify-content-center">
              <AppMediaViewer
                width="75%"
                aspectRatio="1/1"
                class="rounded-circle"
              ></AppMediaViewer>
            </div>
            <!-- description -->
            <div class="offset-1 col-9">
              <p class="text-body-3"
                 :class="{ 'font-weight-bolder': notif_item.is_read === 0 && !read }"
                 v-html="notif_item.message"
              ></p>
              <p class="text-caption text-gray m-0">
                {{ timePassedCal(notif_item.rawDate).timePassedString }}
              </p>
            </div>
          </div>
        </div>
        <router-link tag="div" :to="{ name: 'NotificationList' }">
          <AppBtn
            v-if="!lastNotification"
            text
            class="mt-3 w-100 bg-gray-light-3 rounded-none"
            color="info"
            :loading="loading"
            @click="fetchNotifs"
          >
            See All Notifications
          </AppBtn>
        </router-link>
      </div>
    </div>
  </AppDrawer>
</template>

<script>
import AppDrawer from '@/shared/elements/AppDrawer.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
import AppIcon from '@/shared/elements/AppIcon.vue';
import timePassedCal from '../../../shared/utils/timePassedCalculator';
import Notifications from '@/shared/api/Notifications';
import { DateTime } from 'luxon';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';
export default {
  name: 'NavigationDrawer',

  components: { AppDrawer, AppIcon, AppMediaViewer, AppBtn },

  mixins: [_appDefaultInput],

  props: {
    value: { type: Boolean, default: false },
    hasNotif: { type: Boolean, default: false },
  },

  data() {
    return {
      date: '',
      notifications: [],
      page: 1,
      totalNotifs: 0,
      lastNotification: false,
      loading: false,
    };
  },

  methods: {
    timePassedCal,
    async fetchNotifs() {
      this.loading = true;
      const response = await Notifications.getNotifications({
        params: { page: this.page },
      });
      this.loading = false;

      const data = response.data.data;
      const unreadNotifications = data.filter((notif) => notif.is_read === 0);

      if (unreadNotifications.length > 0) {
        this.$emit('hasNotification');
      } else {
        this.$emit('hasNoNotification');
      }

      data.forEach((notif) => {
        const notification = notif;
        notification.rawDate = DateTime.fromISO(notif.created_at).toFormat(
          'yyyy-MM-dd HH:mm:ss'
        );
        const date = DateTime.fromISO(notif.created_at).toFormat('DDD');
        const existingNotifIndex = this.notifications.findIndex(
          (existNotif) => existNotif.date === date
        );
        if (existingNotifIndex >= 0) {
          this.notifications[existingNotifIndex].notifications.push(
            notification
          );
        } else {
          this.notifications.push({
            date: date,
            notifications: [notification],
          });
        }
      });
      if (response.data.last_page === this.page) {
        this.lastNotification = true;
      } else {
        this.page += 1;
      }
    },
  },
  mounted() {
    this.fetchNotifs();
  },
  updated() {
    this.read = this.hasNotif;
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_colors';

.notification-drawer {
  width: 100%;
  height: 100%;

  &-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    &-item:hover {
      @extend .bg-gray-light-4;
    }
  }
}
</style>
