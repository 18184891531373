<template>
  <div class="topbar-notification-icon" v-on="$listeners">
    <div class="topbar-notification-icon-notify bg-white">
      <div
        v-if="hasNotif"
        class="topbar-notification-icon-notify-icon rounded-circle bg-danger"
      ></div>
    </div>
    <AppIcon hoverable name="notification"></AppIcon>
  </div>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
export default {
  name: 'TopbarNotificationIcon',

  components: { AppIcon },

  props: {
    hasNotif: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.topbar-notification-icon {
  position: relative;
  &-notify {
    position: absolute;
    right: 1px;
    right: 1px;

    &-icon {
      margin: 2px;
      height: 5px;
      width: 5px;
    }
  }
}
</style>
