<template>
  <SearchItemsContainer>
    <li
      v-for="item in $options.filters.limitItems(items)"
      :key="`product-${item.id}`"
      @click="$emit('item-click', item)"
      class="product-searches"
    >
      <span class="img-container">
        <img :src="item.img" />
      </span>
      <p>{{ item.description }}</p>
    </li>
    <SearchShowAllResults
      v-show="items.length > 3"
      :count="items.length"
      @click="$emit('see-all-click')"
    />
  </SearchItemsContainer>
</template>

<script>
import SearchItemsContainer from './SearchItemsContainer.vue';
import SearchShowAllResults from './SearchShowAllResults.vue';
import SearchFilters from './SearchFiltersMix';

export default {
  mixins: [SearchFilters],
  components: {
    SearchShowAllResults,
    SearchItemsContainer,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
