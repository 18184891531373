<template>
    <li class="show-all-results" @click="$emit('click')">
        <p>Show all {{count}} results</p>
        <span class="img-container">
            <AppIcon name="arrow_right"></AppIcon>
        </span>
    </li>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
export default {
    components: {
        AppIcon
    },
    props: {
        count: {
            type: Number,
            default: 0
        }
    }
}
</script>
