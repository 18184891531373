<template>
  <SearchItemsContainer>
    <li
      v-for="item in $options.filters.limitItems(items)"
      :key="`category-${item.id}`"
      @click="$emit('item-click', item)"
      class="order-searches"
    >
      <div>
        <h3>{{ item.category }}</h3>
      </div>
    </li>
    <SearchShowAllResults
      v-show="items.length > 3"
      :count="items.length"
      @click="$emit('see-all-click')"
    />
  </SearchItemsContainer>
</template>

<script>
import SearchItemsContainer from './SearchItemsContainer.vue';
import SearchShowAllResults from './SearchShowAllResults.vue';
import SearchFilters from './SearchFiltersMix';

export default {
  mixins: [SearchFilters],
  components: {
    SearchShowAllResults,
    SearchItemsContainer,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
