<template>
  <div
    v-if="!$route.meta.hideTopbar"
    class="topbar bg-white d-flex align-items-center p-2 p-md-2 pl-md-3 pr-md-3"
  >
    <!-- Page Display Name -->
    <h2 class="m-0 text-uppercase text-headline-5 text-md-headline-2">
      <slot name="title">
        {{
          $route.meta.pageDisplayName
            ? typeof $route.meta.pageDisplayName === 'string'
              ? $route.meta.pageDisplayName
              : $route.meta.pageDisplayName($route) || $route.name
            : title
        }}
      </slot>
    </h2>
    <!-- Search bar -->
    <div class="ml-1 flex-grow-1">
      <slot></slot>
    </div>
    <div class="topbar-searchbar flex-grow-1 p-1 d-flex align-items-center ml-auto">
      <SearchInput :active="true" v-model="search.value" @recent="recentChanged"/>
      <!-- <transition name="topbar-searchbar-input"> -->
      <!-- <AppInput
          v-if="search.active"
          hideError
          class="topbar-searchbar-input mr-3"
        ></AppInput> -->
      <!-- </transition> -->
      <!-- <AppIcon
        hoverable
        name="search"
        class="topbar-searchbar-icon"
        @click="toggleSearch"
      ></AppIcon> -->
    </div>
    <!-- Notification -->
    <TopbarNotificationIcon
      class="m-2 ml-3 ml-md-5 mr-md-5"
      :hasNotif="hasNotif"
      @click="notificationClick"
    ></TopbarNotificationIcon>
    <NotificationDrawer
      v-model="showNotif"
      :hasNotif="read"
      @hasNotification="hasNotif = true"
      @hasNoNotification="hasNotif = false"
    ></NotificationDrawer>

    <slot name="prepend-icons"></slot>
  </div>
</template>
<script>
// import AppIcon from '@/shared/elements/AppIcon.vue';
// import AppInput from '@/shared/elements/AppInput.vue';
import TopbarNotificationIcon from './TopbarNotificationIcon.vue';
import NotificationDrawer from '../NotificationDrawer/NotificationDrawer.vue';
import { mapGetters } from 'vuex';
import SearchInput from './SearchInput.vue';
import Notifications from '@/shared/api/Notifications';

export default {
  name: 'Topbar',

  components: {
    // AppInput,
    // AppIcon,
    TopbarNotificationIcon,
    NotificationDrawer,
    SearchInput,
  },

  data() {
    return {
      showNotif: false,
      hasNotif: false,
      read: false,
      search: {
        active: false,
        value: '',
      },
    };
  },

  computed: {
    ...mapGetters('page', ['title']),
  },

  methods: {
    recentChanged(val) {
      this.search.value = val
    },
    toggleSearch() {
      this.search.active = !this.search.active;
    },

    async notificationClick() {
      this.showNotif = !this.showNotif;

      await Notifications.readNotifications();

      this.hasNotif = false;
      this.read = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  height: 86px;

  &-searchbar-input {
    max-width: 300px;
    &-enter-active,
    &-leave-active {
      transition: max-width 0.2s ease-in-out, opacity 0.15s;
    }
    &-enter,
    &-leave-to {
      max-width: 0;
      opacity: 0;
    }
  }
}
</style>
