<template>
  <transition name="app-drawer">
    <div class="app-drawer" v-if="value">
      <div
        v-if="value"
        class="app-drawer-container d-flex"
        :class="{ 'justify-content-start': left, 'justify-content-end': right }"
      >
        <div
          class="w-100 h-100 position-absolute"
          @click="innerVal = false"
        ></div>
        <div
          class="app-drawer-drawer bg-white position-relative"
          :style="{ '--width': drawerWidth(width) }"
        >
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';
export default {
  name: 'AppDrawer',

  mixins: [_appDefaultInput],

  props: {
    value: { type: Boolean, default: false },
    app: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    width: { type: [String, Number], default: 500 },
  },

  methods: {
    drawerWidth(width) {
      return isNaN(width) ? width : `${width}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-drawer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;

  &-enter-active,
  &-leave-active {
    transition: opacity 0.15s ease-out;

    .app-drawer-drawer {
      transition: width 0.2s ease-out;
    }
  }
  &-enter,
  &-leave-to {
    opacity: 0;
    .app-drawer-drawer {
      width: 0;
    }
  }

  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &-drawer {
    max-width: 100%;
    width: var(--width);
    height: 100%;
  }
}
</style>
