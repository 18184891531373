import axios from 'axios';
import { URL_PREFIX } from './variables';

export const getOrders = (...rest) => axios.get(`${URL_PREFIX}/order`, ...rest);
export const getOrder = (id, ...rest) =>
  axios.get(`${URL_PREFIX}/order/${id}`, ...rest);
export const postOrder = (...rest) =>
  axios.post(`${URL_PREFIX}/order`, ...rest);
export const editOrder = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/order/${id}?_method=PUT`, ...rest);
export const getQuotes = (...rest) =>
  axios.get(`${URL_PREFIX}/order/quote`, ...rest);
export const getQuote = (id, ...rest) =>
  axios.get(`${URL_PREFIX}/order/quote/${id}`, ...rest);
export const sendQuote = (...rest) =>
  axios.post(`${URL_PREFIX}/order/quote`, ...rest);
export const editQuote = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/order/quote/${id}?_method=PUT`, ...rest);
export const deleteQuote = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/order/quote/${id}?_method=DELETE`, ...rest);
export const editQuoteProduct = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/order/quote/product/${id}?_method=PUT`, ...rest);
export const cloneOrder = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/order/clone/${id}`, ...rest);

export const autoApproveOrder = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/order/autoapprove/${id}`, ...rest);

export default {
  getOrders,
  getOrder,
  postOrder,
  editOrder,
  getQuotes,
  getQuote,
  sendQuote,
  editQuote,
  editQuoteProduct,
  deleteQuote,
  cloneOrder,
  autoApproveOrder,
};
