<template>
  <div class="title-container">
    <h4>{{ title }}</h4>
    <span v-show="showClear" @click="$emit('clear')" class="clear"
      >Clear History</span
    >
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    showClear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
