import { isNil } from 'lodash';

export const ProductDataIn = (product) => {
  const primaryImage = (product?.files || []).find(
    ({ type }) => type === 'primary_image'
  );
  const extra_image = (product?.files || []).find(
    ({ type }) => type === 'extra_image'
  );

  return {
    id: product?.id || null,
    img:
      primaryImage?.filename ||
      extra_image?.filename ||
      require('@/assets/pocketyard_logo_black.svg'),
    description: product.name || '',
  };
};

export const OrderDataIn = (order) => {
  const count = order?.products?.length || 0;
  const [item] = order?.products;

  let description = `${count} item${count > 1 ? 's' : ''}`;
  if (!isNil(item)) {
    description = `${description}, Including ${item?.product_name || ''}`;
  }

  return {
    id: order?.id || null,
    user: order?.full_name || '',
    img:
      order?.user?.information?.profile_picture || require('@/assets/pocketyard_logo_black.svg'),
    description: description,
    status: order?.status?.label || '',
    status_color: order?.status?.color || '',
  };
};
