export const recentSearches = ['Masonry', 'Heavy Tools', 'reinforcement metal']
export const products = [
    {
        id: 1,
        img: "http://placekitten.com/200/300",
        description: "60x60 Ceramic Floor Tiles"
    },
    {
        id: 2,
        img: "http://placekitten.com/200/300",
        description: "60x60 Pavement Floor tiles"
    },
    {
        id: 3,
        img: "http://placekitten.com/200/300",
        description: "60x60 Kitchen BachSplash Tiles"
    }
]

export const orders = [
    {
        id: 1,
        user: "Stephanie Dodds",
        img: "http://placekitten.com/200/300",
        description: "4 items, Including '60x60' Tiles",
        status: "pending_request"
    },
    {
        id: 2,
        user: "Stephanie Dodds",
        img: "http://placekitten.com/200/300",
        description: "4 items, Including '60x60' Tiles",
        status: "approved"
    },
    {
        id: 3,
        user: "Stephanie Dodds",
        img: "http://placekitten.com/200/300",
        description: "4 items, Including '60x60' Tiles",
        status: "completed"
    }
]

export const quickTools = [
    {
        id: 1,
        icon: "add_filled",
        description: "Add Product",
        link: '/product/add',
        banned_personas: ['buyer'],
    },
    {
        id: 2,
        icon: "add_filled",
        description: "Add Order",
        link: '/order/request',
        banned_personas: ['vendor'],
    },
    {
        id: 2,
        icon: "add_filled",
        description: "Manage Category",
        link: '/category',
        banned_personas: ['vendor', 'buyer'],
    },
    {
        id: 2,
        icon: "add_filled",
        description: "Add Company",
        link: '/company/add',
        banned_personas: ['vendor', 'buyer'],
    },
]