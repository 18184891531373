import axios from 'axios';
import { URL_PREFIX } from './variables';
// products
export const getProducts = (...rest) =>
  axios.get(`${URL_PREFIX}/product`, ...rest);
export const getProduct = (id, ...rest) =>
  axios.get(`${URL_PREFIX}/product/${id}`, ...rest);
export const postProduct = (...rest) =>
  axios.post(`${URL_PREFIX}/product`, ...rest);
export const editProduct = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/${id}?_method=PUT`, ...rest);
export const deleteProduct = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/${id}?_method=DELETE`, ...rest);
// product quantity options
export const editQuantityOption = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/quantity/${id}?_method=PUT`, ...rest);
export const deleteQuantityOption = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/quantity/${id}?_method=DELETE`, ...rest);

export const deleteQuantityOptionForProduct = (...rest) =>
  axios.post(`${URL_PREFIX}/product/quantity/new`, ...rest);

// product variants
export const editVariant = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/varianty/${id}?_method=PUT`, ...rest);
export const deleteVariant = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/variant/${id}?_method=DELETE`, ...rest);
export const deleteVariants = (...rest) =>
  axios.post(`${URL_PREFIX}/product/variant/batch?_method=DELETE`, ...rest);
// product file
export const deleteFile = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/file/${id}?_method=DELETE`, ...rest);
// import products
export const importProducts = (...rest) =>
  axios.post(`${URL_PREFIX}/import/product`, ...rest);

// product categories
export const getCategories = (...rest) =>
  axios.get(`${URL_PREFIX}/product/category`, ...rest);
export const postCategory = (category, details = null, ...rest) =>
  axios.post(
    `${URL_PREFIX}/product/category`,
    { category, details: details || category },
    ...rest
  );
export const editCategory = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/category/${id}?_method=PUT`, ...rest);
export const deleteCategory = (id, ...rest) =>
  axios.post(`${URL_PREFIX}/product/category/${id}?_method=DELETE`, ...rest);

// product quantity options
export const getQuantities = (...rest) =>
  axios.get(`${URL_PREFIX}/quantity`, ...rest);
export const postQuantity = (...rest) =>
  axios.post(`${URL_PREFIX}/quantity`, ...rest);
export default {
  getProducts,
  getProduct,
  postProduct,
  editProduct,
  deleteProduct,
  editQuantityOption,
  deleteQuantityOption,
  deleteQuantityOptionForProduct,
  editVariant,
  deleteVariant,
  deleteVariants,
  deleteFile,
  importProducts,
  getCategories,
  postCategory,
  editCategory,
  deleteCategory,
  getQuantities,
  postQuantity,
};
