<template>
  <div
    class="
      loading-display
      d-flex
      align-items-center
      justify-content-center
      text-gray
      p-5
    "
    :class="{ 'w-100 h-100': fillSpace }"
  >
    <AppIcon
      name="progress_bar_round"
      class="loading-display-icon"
      :size="50"
    ></AppIcon>
    <h1 v-if="!noText" class="ml-3 text-uppercase font-weight-5">Loading...</h1>
  </div>
</template>

<script>
import AppIcon from './AppIcon.vue';

export default {
  name: 'AppLoading',

  components: { AppIcon },

  props: {
    fillSpace: { type: Boolean, default: false },
    noText: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.loading-display {
  &-icon {
    animation: loading 3.2s linear 0s infinite;
  }
}
// Loading rotation animation
@keyframes loading {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-360deg);
  }
}
</style>
