<template>
  <SearchItemsContainer>
    <li
      v-for="item in $options.filters.limitItems(items)"
      :key="`order-${item.id}`"
      @click="$emit('item-click', item)"
      class="order-searches"
    >
      <span class="img-container">
        <img :src="item.img" />
      </span>
      <div>
        <h3>{{ item.user }}</h3>
        <p>{{ item.description }}</p>
        <span
          class="status-badge"
          :style="{ background: item.status_color || 'none' }"
        >
          <span v-if="item.status !== 'Pending'" class="mr-1">
            <AppIcon name="checkmark"></AppIcon>
          </span>
          {{ item.status }}
        </span>
      </div>
    </li>
    <SearchShowAllResults
      v-show="items.length > 3"
      :count="items.length"
      @click="$emit('see-all-click')"
    />
  </SearchItemsContainer>
</template>

<script>
import SearchItemsContainer from './SearchItemsContainer.vue';
import SearchShowAllResults from './SearchShowAllResults.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import SearchFilters from './SearchFiltersMix';

export default {
  mixins: [SearchFilters],
  components: {
    AppIcon,
    SearchShowAllResults,
    SearchItemsContainer,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
