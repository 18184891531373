<template>
  <div
    @click="showResults = true"
    v-click-outside="() => (showResults = false)"
    class="search-container w-100"
    v-show="active"
  >
    <div class="searchbar-container w-100">
      <AppIcon name="search" class="topbar-searchbar-icon"></AppIcon>
      <AppInput
        hideError
        :value="value"
        @input="onChange"
        class="topbar-searchbar-input w-100 mr-3"
      >
      </AppInput>
    </div>
    <div v-if="showResults" class="search-results-container">
      <SearchItemsTitle
        title="RECENT SEARCHES"
        :showClear="true"
        @clear="clearRecentSearches"
      />
      <ul class="search-items-container">
        <li
          v-for="item in $options.filters.limitItems(recentSearches)"
          :key="item"
          class="recent-searches"
          @click="() => applyRecentSearch(item)"
        >
          {{ item }}
        </li>
      </ul>

      <SearchItemsTitle title="YOUR PRODUCT" />
      <AppLoading noText v-if="productLoading"></AppLoading>
      <SearchProductsItem
        v-else
        key="products"
        :items="products"
        @item-click="(item) => addRecentSearch(item, 'product')"
        @see-all-click="seeAllProducts"
      />

      <SearchItemsTitle title="ORDERS" />
      <AppLoading noText v-if="orderLoading"></AppLoading>
      <SearchOrderItems
        v-else
        key="orders"
        :items="orders"
        @item-click="(item) => addRecentSearch(item, 'order')"
        @see-all-click="seeAllOrders"
      />

      <div>
        <SearchItemsTitle title="CATEGORIES" />
        <AppLoading noText v-if="categoryLoading"></AppLoading>
        <SearchCategoryItems
          v-else
          key="category"
          :items="categories"
          @item-click="(item) => addRecentSearch(item, 'category')"
          @see-all-click="seeAllCategories"
        ></SearchCategoryItems>
      </div>

      <SearchItemsTitle title="QUICK TOOLS" />
      <ul class="search-items-container">
        <template
          v-for="(item, index) in $options.filters.limitItems(quickTools, 4)"
        >
          <li
            v-if="!item.banned_personas.includes(user.persona)"
            :key="'quick-tools-' + index"
            class="quick-tools"
            @click="() => $router.push(item.link)"
          >
            <span class="img-container">
              <AppIcon :name="item.icon"></AppIcon>
            </span>
            <p>{{ item.description }}</p>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import AppInput from '@/shared/elements/AppInput.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import SearchOrderItems from './SearchOrderItems.vue';
import SearchProductsItem from './SearchProductsItem.vue';
import SearchCategoryItems from './SearchCategoryItems.vue';
import SearchItemsTitle from './SearchItemsTitle.vue';
import * as results from './search-results';
import { getOrders } from '@/shared/api/Orders';
import { getProducts, getCategories } from '@/shared/api/Products';
import { isEmpty, debounce, uniq } from 'lodash';
import {
  OrderDataIn,
  ProductDataIn,
} from '@/shared/transformers/SearchResults';
import SearchFiltersMix from './SearchFiltersMix';
import ClickOutside from 'vue-click-outside';
import { topbarSearchEvent } from '@/shared/events';
import AppLoading from '../../../shared/elements/AppLoading.vue';

const getStatus = (status) => {
  let meta = {
    label: '',
    color: '',
  };
  switch (status) {
    case 'completed':
      meta = { label: 'Completed', color: 'green' };
      break;
    case 'approved':
      meta = { label: 'Approved', color: 'blue' };
      break;
    case 'declined':
      meta = { label: 'Declined', color: 'orange' };
      break;
    case 'pending':
      meta = { label: 'Pending', color: 'gray' };
      break;
    default:
      break;
  }

  return meta;
};

export default {
  mixins: [SearchFiltersMix],
  components: {
    AppInput,
    AppIcon,
    SearchOrderItems,
    SearchProductsItem,
    SearchItemsTitle,
    SearchCategoryItems,
    AppLoading,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      default: null,
    },
  },

  data() {
    return {
      user: this.$store.getters['auth/user'],
      showResults: false,
      products: [],
      orders: [],
      categories: [],
      categoryResponse: null,
      recentSearches: [],
      productLoading: false,
      orderLoading: false,
      categoryLoading: false,
    };
  },

  directives: {
    ClickOutside,
  },
  mounted: function () {
    // load recent searches
    const items = localStorage.getItem('recent-searches');
    const parseItems = JSON.parse(items) || [];
    this.recentSearches = uniq(parseItems);

    if (this.$route.query && this.$route.query.search) {
      this.applyRecentSearch(this.$route.query.search);
    }
  },
  methods: {
    applyRecentSearch(val) {
      this.$emit('recent', val);
      // this.value = val;
      this.onChange(val);
    },

    onChange(val) {
      this.showResults = true;
      topbarSearchEvent.emit(val);

      this.$emit('input', val);
      this.getProducts(val);
      this.getOrders(val);
      this.getCategories(val);
      this.getRecentSearches(val);
    },

    clearRecentSearches() {
      this.$emit('input', '');
      localStorage.removeItem('recent-searches');
      this.recentSearches = [];
    },

    seeAllOrders() {
      this.$router.push('/order');
    },

    seeAllProducts() {
      const data = {
        name: 'ProductList',
      };

      if (this.value) {
        data.query = {
          ...this.$route.query,
          search: this.value,
        };
      }

      if ((Object.keys(data.query).length != Object.keys({...this.$route.query}).length) && data.query !== undefined ) {
        this.$router.push(data);
      }

      if (this.$route.name === 'ProductList') {
          location.reload();
      }
    },

    seeAllCategories() {
      this.$router.push(`/category?search=${this.value}`);
    },

    async addRecentSearch(item, type = 'order') {
      if (isEmpty(this.value)) return;

      const items = await localStorage.getItem('recent-searches');
      const parseItems = JSON.parse(items) || [];
      localStorage.setItem(
        'recent-searches',
        JSON.stringify(uniq([...parseItems, this.value]))
      );
      let link = `/order/${item.id}`;
      if (type === 'product') link = `/product/${item.id}`;
      else if (type === 'category') link = `/category/?search=${item.category}`;

      this.$router.push(link);
    },

    getProducts: debounce(async function (search) {
      if (search === '' || search === null || search === undefined) {
        this.products = [];
        this.productLoading = false;
        return false;
      }

      let params = {search};

      if (this.user.persona === 'buyer') {
        params.status = 'live';
      }

      this.productLoading = true;
      const response = await getProducts({
        params: params,
      });
      this.$nextTick(() => {
        const products = (response?.data || []).map((product) => {
          return ProductDataIn(product);
        });
        if (this.productLoading === true) {
          this.products = products;
          this.productLoading = false;
        }
      });
    }, 300),

    getOrders: debounce(async function (search) {
      if (search === '' || search === null || search === undefined) {
        this.orders = [];
        this.orderLoading = false;
        return false;
      }

      this.orderLoading = true;
      const response = await getOrders({
        params: {
          search,
        },
      });

      this.$nextTick(() => {
        const orders = (response?.data?.data || []).map((order) =>
          OrderDataIn({ ...order, status: getStatus(order?.status) })
        );

        if (this.orderLoading === true) {
          this.orders = orders;
          this.orderLoading = false;
        }
      });
    }, 300),

    getCategories: debounce(async function (search) {
      if (search === '' || search === null || search === undefined) {
        this.categories = [];
        this.categoryLoading = false;
        return false;
      }

      let response = {};
      if (this.categoryResponse) {
        response = this.categoryResponse;
      } else {
        response = await getCategories({});
        this.categoryResponse = response;
      }

      this.$nextTick(() => {
        const categories = (response?.data || []).filter((category) =>
          category.category.toLowerCase().includes(search)
        );

        if (this.categoryLoading === true) {
          this.categoryLoading = false;
          this.categories = categories;
        }
      });
    }, 300),

    getRecentSearches(search) {
      const items = localStorage.getItem('recent-searches');
      const parseItems = JSON.parse(items) || [];
      this.recentSearches = uniq(parseItems).filter((val) =>
        val.includes(search)
      );
    },
  },

  computed: {
    // products() {
    //   return results.products;
    // },
    // orders() {
    //   return results.orders;
    // },
    quickTools() {
      return results.quickTools.filter(({ description }) => {
        if (typeof this.value.toLowerCase === 'function') {
          return description.toLowerCase().includes(this.value.toLowerCase());
        }
        return false;
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/theme/_breakpoints';

.search-container {
  /* display: revert; */
  position: relative;

  & .topbar-searchbar-input {
    // max-width: 260px;
    // min-width: 260px;

    // @include media-md('above') {
    //   min-width: 400px;
    // }

    & .app-input-container {
      border-color: var(--primary);
      padding-left: 30px;
    }
  }

  .searchbar-container .topbar-searchbar-icon {
    position: absolute;
    top: 9px;
    z-index: 1;
    left: 10px;
    color: var(--icon-color);
  }

  & span.status-badge .app-icon svg {
    width: 14px;
    height: 16px;
  }
}

.search-results-container {
  position: absolute;
  z-index: 1999;
  background: #4f4f4f;
  padding: 8px;
  width: calc(100% - 16px);
  top: 48px;
  /* box-sizing: border-box; */
  border-radius: 3px;
  color: #fff;
  max-height: 500px;
  overflow: auto;

  @include media-md('below') {
    width: 70vw;
    left: -15vw;
  }

  & ul {
    padding: 0;
    list-style: none;
    margin: 0;

    & li {
      padding: 10px 5px;
      font-size: 15px;
      font-family: inter;
      letter-spacing: 0.4px;
      font-style: normal;
      font-weight: normal;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
  }

  & .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
    /* opacity: 60%; */
    border-bottom: 2px solid #7d7d7d73;
    padding-bottom: 8px;
    margin-bottom: 2px;

    & h4 {
      font-weight: 400;
      font-size: 15px;
      letter-spacing: 0.8px;
      color: #fff;
      margin: 0;
      line-height: 22px;
    }

    & .clear {
      opacity: 0.6;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  & li.order-searches,
  & li.product-searches {
    display: flex;
  }

  & .search-items-container span.img-container {
    min-width: 32px;
    width: 32px;
    height: 32px;
    position: relative;
    overflow: hidden;
    margin-right: 15px;
    align-self: start;

    & img {
      width: 100%;
      height: auto;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /* border: 1px; */
    }
  }

  & .product-searches p {
    margin: 0 !important;
    font-family: 'Inter';
    font-size: 14px;
    letter-spacing: 0.4px;
    opacity: 1;
  }

  li.show-all-results {
    padding-top: 20px !important;
    & p {
      font-size: 14px;
      opacity: 0.8;

      &::hover {
        opacity: 0.5;
      }
    }
  }

  li.quick-tools {
    & p {
      margin: 0;
    }

    & .img-container {
      align-items: center;
      display: flex;
    }
  }

  & .order-searches span.img-container {
    border-radius: 50%;
  }

  & .order-searches h3 {
    font-family: 'Inter';
    font-weight: 500;
    letter-spacing: 0.2px;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
  }

  & .order-searches p {
    font-family: 'Inter';
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    opacity: 0.7;
    margin-bottom: 3px;
  }

  & span.status-badge {
    font-family: 'Inter';
    font-size: 13px;
    line-height: 20px;
    padding: 2px 6px;
    border-radius: 2px;
    display: flex;
    width: fit-content;

    & .app-icon svg {
      width: 18px;
    }

    &.pending {
      background-color: #98e8ff;
      color: #0e3f4c;
    }

    &.approved {
      background-color: #316723;
      color: #fff;
    }

    &.completed {
      background-color: #dceed8;
      color: #316723;
    }
  }
}
</style>
